import { EnvironmentInjector, inject, runInInjectionContext } from '@angular/core';
import { WorkspaceUser } from '@app/_classes/timeghost';
import { endOfToday, isSameDay, isToday } from 'date-fns/esm';
import { firstBy } from 'thenby';
import { ComegoTime, UserSettings } from 'timeghost-api';
import { isAdmin } from '../permission';
import { WorkingHourViolation } from '../rulebreakUtil';
import {
  CreateScheduleDayParserValue,
  createDateRangeArray,
  createScheduleDayParserFromUser,
  stringify,
} from '../utils';
import { CheckWorkingHourErrorsOptions, checkWorkingHourErrors } from '../workingHourUtils';
type SuperviseBreakTime = {
  times: (ComegoTime & { meta: Record<string, any> })[];
  issues: (ReturnType<typeof checkWorkingHourErrors>[0] & { ref: ComegoTime[] })[];
  date: Date;
  user: WorkspaceUser;
};
type ParseUserRuleBreakOptions = { from?: Date; to?: Date; endToday?: Date };

const _checkWorkingHourErrors = (
  injector: EnvironmentInjector,
  userSettings: UserSettings,
  times: ComegoTime[],
  date?: Date,
  options?: Partial<CheckWorkingHourErrorsOptions>,
) => {
  let errors: ReturnType<typeof checkWorkingHourErrors> = checkWorkingHourErrors.bind(injector)(
    times,
    userSettings,
    date,
    true,
    options,
  );
  if (!errors?.length) return null;
  return errors;
};
type ParseUserRuleBreakFn = (
  user: WorkspaceUser,
  userSettings: UserSettings,
  flatenedTimes: ComegoTime[],
  options?: ParseUserRuleBreakOptions,
) => SuperviseBreakTime[];

function parseUserRulebreaks(
  this: EnvironmentInjector,
  user: WorkspaceUser,
  userSettings: UserSettings,
  flatenedTimes: ComegoTime[],
  options?: ParseUserRuleBreakOptions,
) {
  const sortedRef = flatenedTimes.toSorted(firstBy((x) => x.start, 'asc'));
  const firstItem = sortedRef[0]?.start,
    lastItem = sortedRef[sortedRef.length - 1]?.start;
  const {
    from = firstItem && new Date(firstItem),
    to = lastItem && new Date(lastItem),
    endToday = endOfToday(),
  } = options ?? {};
  const userId = user.id;
  const now = new Date();
  const groupedTimes = createDateRangeArray(from, to)[0]
    .map(([wdate]) => {
      const date = isToday(wdate) ? now : wdate;
      const times = flatenedTimes
        .filter((t) => isSameDay(new Date(t.start), date))
        .map((x: any) => ({
          ...x,
          meta: Object.assign({}, x.meta || {}, {
            editable: x.user.id === user.id || isAdmin(userSettings),
            recording: x.meta?.recording ?? x.recording ?? !x.end,
          }),
          timeDiff: (new Date(x.end ?? now.getTime()).getTime() - new Date(x.start).getTime()) / 1000,
          recording: x.meta?.recording ?? x.recording ?? !x.end,
        }));
      const dayParser = createScheduleDayParserFromUser({ ...userSettings, id: userId });
      let schedule = dayParser(times.filter((d) => d.type !== 'pause') as any, date) as CreateScheduleDayParserValue &
        Record<string, any>;
      const diffMs = ~~schedule.cap - ~~schedule.usage;
      schedule.future = 0;
      schedule.missing = 0;
      schedule.overtime = 0;
      schedule.summed = ~~schedule.usage;
      schedule.difference = Math.floor(date < endToday ? -diffMs : -diffMs > 0 ? -diffMs : 0);
      if (diffMs > 0) schedule.overtime += diffMs;
      else if (date < endToday) schedule.missing += diffMs;

      if (date >= endToday) {
        const futureMs = schedule.enabled
          ? (~~schedule.cap - ~~schedule.usage).clampThis(0.0)
          : Math.floor(schedule.usage);
        schedule.future += futureMs;
      }
      return { date, issues: [], times, user, schedule } as SuperviseBreakTime;
    })
    .reduce((acc, r) => {
      let lastErrorIdx = 0;
      const seenErrors = new Set<string>();
      const isStartOfWeek = r.date.getUTCDay() === 0;
      r.times = r.times
        .sort(firstBy((x: ComegoTime) => x.start, 'asc'))
        .map((t, tidx, tarr) => {
          const currentTimeErrors = _checkWorkingHourErrors(
            this,
            userSettings,
            [...tarr.slice(0, tidx), t].uniqBy((d: any) => d.id) as any,
            r.date,
            {
              nextDayRestEnabled: !isStartOfWeek,
              prevDayRestEnabled: true,
              breakBetweenDaysTypeFilter: 'work',
              recordingTimeDiffDateFallback: true,
            },
          );

          t.meta['errors'] = [];
          if (currentTimeErrors?.length) {
            // Filter out previously seen errors
            const newErrors = currentTimeErrors.filter((error) => {
              // Create a unique key for each error type
              const errorKey = `${error.type}_${error.value}_${JSON.stringify(error.args)}`;
              if (seenErrors.has(errorKey)) {
                return false;
              }
              seenErrors.add(errorKey);
              return true;
            });

            if (newErrors.length) {
              lastErrorIdx = tidx;
              t.meta['errors'].push(...newErrors);
              r.issues.push(...newErrors.map((x) => ({ ...x, ref: [t] })));
            }
          }
          return t;
        })
        .sort(firstBy((x) => x.start, 'desc'));
      acc.push(r);
      return acc;
    }, [] as SuperviseBreakTime[]);
  return groupedTimes.filter((x) => x.times?.length).sort(firstBy((x) => x.date, 'desc'));
}

export function useUserRulebreaksParser() {
  const injector = inject(EnvironmentInjector);
  return runInInjectionContext(injector, () => parseUserRulebreaks.bind(injector)) as ParseUserRuleBreakFn;
}

type ComegoTimeWithMeta = ComegoTime & Partial<{ recording?: boolean }>;
export const COMEGOTIME_SORT_PRESET = {
  SUPERVISOR_SORT_ACTIVITY: <T extends ComegoTimeWithMeta = ComegoTimeWithMeta>() =>
    firstBy<T>((x: T) => (x.recording || !x.end ? Number.MAX_SAFE_INTEGER : Date.parse(x.end)), 'desc')
      .thenBy((x: T) => x.start)
      .thenBy((x: T) => x.end),
};

export function getComegoPresetSort(key: keyof typeof COMEGOTIME_SORT_PRESET) {
  return COMEGOTIME_SORT_PRESET[key]();
}
type UniqRulebreakType = { type: WorkingHourViolation['type']; value: any; args: any; date: string };
export const uniqRulebreakFn = <T extends UniqRulebreakType = UniqRulebreakType>(err: T) =>
  `${err.type}_${err.value}_${stringify(err.args)}`;
export const uniqRulebreakTypeFn = <T extends UniqRulebreakType = UniqRulebreakType>(err: T) =>
  `${err.type}_${stringify(err.args)}`;
export const uniqRulebreakTypeDateFn = <T extends UniqRulebreakType = UniqRulebreakType>(err: T) =>
  `${uniqRulebreakTypeFn(err)}_${stringify(err.date)}`;
